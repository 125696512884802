<template>
  <div class="content">
    <a-form layout="inline">
      <a-form-item label="归集点名称">
        <a-input v-model="query.centerName" placeholder="请输入归集点名称"/>
      </a-form-item>

      <a-form-item label="采集点名称">
        <a-input v-model="query.pointName" placeholder="请输入采集点名称"/>
      </a-form-item>

      <a-form-item label="街道名称">
        <a-input v-model="query.streetCode" placeholder="请输入街道编号"/>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" @click="customQuery">查询</a-button>
        <a href="https://zhongshi-garbage.oss-cn-shanghai.aliyuncs.com/monitor/VideoWebPlugin.exe " style="margin-left: 30px;">插件下载</a>
      </a-form-item>
    </a-form>

    <a-table :columns="columns" :data-source="data" :loading="loading"
             :pagination="pagination" rowKey="equipmentCode"
             @change="handleTableChange">

      <span slot="action" slot-scope="text, record">
         <a-space>
           <a-button type="primary" @click="toPreview(record)">预览</a-button>
           <a-button type="primary" @click="toPlayback(record)"> 回放</a-button>
         </a-space>
      </span>
    </a-table>

    <select-point ref="selectPoint" @bind="bind"/>
  </div>
</template>

<script>
import {queryMonitorList} from "@/api/monitor";
import SelectPoint from "./components/selectPoint";
import {bindEquipmentApi} from "@/api/equipment";
import moment from "moment";

export default {
  name: "monitorList",
  components: {
    SelectPoint
  },
  data() {
    return {
      query: {
        disposalSiteName: undefined,
        sort: undefined,
        order: undefined
      },
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0
      },
      columns: [
        {
          title: '监控设备编号',
          dataIndex: 'equipmentCode',
          key: 'equipmentCode',
          scopedSlots: {customRender: 'equipmentCode'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '监控设备名称',
          dataIndex: 'information.name',
          key: 'information.name',
          scopedSlots: {customRender: 'information.name'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '采集点',
          dataIndex: 'collectionPoint.pointName',
          key: 'collectionPoint.pointName',
          scopedSlots: {customRender: 'collectionPoint.pointName'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '街道',
          dataIndex: 'collectionPoint.address.streetName',
          key: 'collectionPoint.address.streetName',
          scopedSlots: {customRender: 'collectionPoint.address.streetName'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '通道',
          dataIndex: 'information.extend.channelTypeName',
          key: 'information.extend.channelTypeName',
          scopedSlots: {customRender: 'information.extend.channelTypeName'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: {customRender: 'action'},
          align: 'center'
        },
      ],
      data: [],
      loading: true,
      currentMonitor: undefined,
    }
  },
  mounted() {
    this.queryMonitorList()
  },
  methods: {
    async queryMonitorList() {
      this.loading = true

      let data = await queryMonitorList(this.convertQuery())
      this.convertPagination(data.result)
      this.data = this.convertData(data.result.content)

      this.loading = false
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.pageSize = pagination.pageSize
      this.pagination.current = pagination.current
      this.query.sort = sorter.field
      this.query.order = sorter.order

      this.queryMonitorList()
    },
    customQuery() {
      this.pagination.current = 1
      this.queryMonitorList()
    },
    convertQuery() {
      return {
        ...this.query,

        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.current,
        sort: this.query.sort,
        order: this.query.order,
      }
    },
    convertPagination(data) {
      this.pagination.current = data.pageNo + 1
      this.pagination.total = data.totalElements
    },
    convertData(data) {
      return data
    },
    showSelectPoint(mointor) {
      this.currentMonitor = mointor
      this.$refs.selectPoint.toggleVisible()
    },
    async bind(point) {
      bindEquipmentApi({
        equipmentCode: this.currentMonitor.equipmentCode,
        pointCode: point.systemCode,
        isBind: true,
      }).then(() => {
        this.$message.success('绑定成功')
        this.queryMonitorList()
        this.$refs.selectPoint.toggleVisible()
      }).catch(() => {
        this.$message.error('绑定失败')
      })
    },
    async unBind(monitor) {
      bindEquipmentApi({
        equipmentCode: monitor.equipmentCode,
        pointCode: monitor.collectionPoint.pointCode,
        isBind: false,
      }).then(() => {
        this.$message.success('绑定成功')
        this.queryMonitorList()
      }).catch(() => {
        this.$message.error('解绑失败')
      })

    },
    toPreview(monitor) {
      this.$router.push({
        name: 'monitorPreview',
        // query: {
        //   equipmentCode: monitor.equipmentCode,
        //   equipment: monitor
        // }
      })
      localStorage.setItem('equipmentCode', monitor.equipmentCode)
    },
    toPreview2(monitor) {
      this.$router.push({
        name: 'monitorPreview2',
        query: {
          equipmentCode: monitor.equipmentCode
        }
      })
    },
    toPlayback(monitor) {
      this.$router.push({
        name: 'monitorPlayback',
        query: {
          // equipmentCode: monitor.equipmentCode,
          // equipment: monitor
        }
      })
      localStorage.setItem('equipmentCode', monitor.equipmentCode)

    }
  },
}
</script>


<style lang="scss" scoped>
.content {
  background-color: white;
  padding: 15px;
}
</style>
